import {
  HorizontalSeparator,
  StyledBullet,
  StyledEndoListingItemContainer,
  StyledEndoListingShadowBoxWrapper,
  StyledEndoListItemFooter,
  StyledEndoListItemHeader,
  StyledEndoListItemHeaderInsurerDetails,
  StyledEndoListItemHeaderInsurerInfo,
} from './styles';
import { IEndoListingItem } from './types';
import {
  StyledHeaderButtonContainer,
  StyledPolicyItemAlertsContainer,
  StyledPolicyListItemHeaderMonetaryInfo,
} from '../PolicyListItem/styles';
import {
  Alerts,
  CardIcon,
  DateFormatUtils,
  LoopButton,
  Typography,
} from '@loophealth/loop-ui-web-library';
import { POLICY_LIST_ITEM_TITLE } from '../PolicyListItem/constants';
import {
  ArrowRightIcon,
  EndoCurrentStatusIcon,
  EndoLivesCountIcon,
  EndoTotalCostIcon,
  EndoUpdatedAtIcon,
} from '../../../assets/images';
import React from 'react';
import PolicyItem from '../PolicyListItem/PolicyItem';
import { formatCurrencyAmount } from '../../../utils/common/Utilities';
import { useHistory, useLocation } from 'react-router-dom';
import { ENDO_STATUS_MESSAGES } from './constants';
import useSegment from '../../../utils/segment/hooks/useSegment';
import { extractPathName, trackClickEvent } from '../../../utils/segment/utils';

export default function EndoListingItem({ endorsement }: IEndoListingItem) {
  const trackClick = useSegment('click');
  const location = useLocation();
  const history = useHistory();
  const isLowCD =
    endorsement.status === 'ENDO_COMPLETE'
      ? false
      : endorsement.cdBalance < endorsement.endoCost;
  const handleStopPropagation: React.MouseEventHandler<HTMLDivElement> = (e) =>
    e.stopPropagation();

  const onViewDetailsClicked = () => {
    trackClick(
      trackClickEvent(
        'TrackStatus_track',
        location?.pathname,
        extractPathName(location?.pathname),
      ),
    );
    history.push(`/endorsement-details?id=${endorsement.id}`);
  };
  const onViewCDAccounts = () => {
    history.push('/cd-accounts#' + endorsement.cdAccountNumber);
  };

  return (
    <StyledEndoListingShadowBoxWrapper>
      <StyledEndoListingItemContainer>
        <StyledEndoListItemHeader>
          <CardIcon imageUrl={endorsement.insurerLogo} />
          <StyledEndoListItemHeaderInsurerInfo data-testid="policy-list-item-insurer-info">
            <Typography variant="medium" weight="semiBold">
              {POLICY_LIST_ITEM_TITLE[endorsement.policyType] || 'Endorsement'}
            </Typography>
            <StyledEndoListItemHeaderInsurerDetails>
              <Typography variant="small" color="secondary">
                {endorsement.insurerName}
              </Typography>
              {endorsement.nickName && (
                <>
                  <StyledBullet />
                  <Typography variant="small" color="secondary">
                    {endorsement.nickName}
                  </Typography>
                </>
              )}
              {endorsement.policyNumber && (
                <>
                  <StyledBullet />
                  <Typography variant="small" color="secondary">
                    Policy No: {endorsement.policyNumber}
                  </Typography>
                </>
              )}
            </StyledEndoListItemHeaderInsurerDetails>
          </StyledEndoListItemHeaderInsurerInfo>
          <StyledPolicyListItemHeaderMonetaryInfo data-testid="policy-list-item-monetary-info">
            <StyledHeaderButtonContainer
              data-testid="policy-list-item-details-button"
              onClick={handleStopPropagation}
            >
              <LoopButton
                variant="secondary"
                size="medium"
                onClick={onViewDetailsClicked}
                iconSrc={ArrowRightIcon}
                iconOrder="right"
              >
                Track Status
              </LoopButton>
            </StyledHeaderButtonContainer>
          </StyledPolicyListItemHeaderMonetaryInfo>
        </StyledEndoListItemHeader>
        <HorizontalSeparator />
        <StyledEndoListItemFooter>
          <PolicyItem
            icon={EndoLivesCountIcon}
            label="Total Life Count"
            value={endorsement.livesCount}
          />
          <PolicyItem
            icon={EndoTotalCostIcon}
            label="Endorsement Cost"
            value={formatCurrencyAmount(endorsement.endoCost)}
          />
          <PolicyItem
            icon={EndoUpdatedAtIcon}
            label="Lives last updated on"
            value={new DateFormatUtils().formatDateString(
              endorsement.updatedAt,
            )}
          />
          <PolicyItem
            icon={EndoCurrentStatusIcon}
            label="Current Status"
            value={ENDO_STATUS_MESSAGES[endorsement.status].statusText}
          />
        </StyledEndoListItemFooter>
        {isLowCD && (
          <StyledPolicyItemAlertsContainer onClick={handleStopPropagation}>
            <Alerts.StatusAlert
              text={
                <Typography variant="small" weight="medium" color="error">
                  Endorsement blocked due to low CD Balance! Please top up your
                  balance to process this endorsement
                </Typography>
              }
              variant="over"
              onButtonClick={onViewCDAccounts}
              buttonText="View CD Account Details"
            />
          </StyledPolicyItemAlertsContainer>
        )}
      </StyledEndoListingItemContainer>
    </StyledEndoListingShadowBoxWrapper>
  );
}
