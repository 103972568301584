import { convertToNearestThousand, groupArrayOfObjectsByKey } from '../../../utils/common/Utilities';
import { EndoState } from '../Employees/types';
import { IEndorsements } from '../Endorsements/ListingPage/types';
import { ICDAccount } from './types';

export const isCDBalanceLow = (accountData: ICDAccount) => {
  return accountData.balance < getRecommendedCDBalance(accountData);
};

export const getRecommendedCDBalance = (accountData: ICDAccount) => {
  const accountsFinalPremium = accountData.policyData.reduce(
    (totalPremium, policy) => totalPremium + policy.finalPremium,
    0,
  );
  // 10% of final premiums -
  return convertToNearestThousand(accountsFinalPremium * 0.1);
};

export const getFirstOngoingEndoCDAccount = (
  cdAccounts: ICDAccount[],
  endorsements: IEndorsements[],
) => {
  const policyGroupedEndo = groupArrayOfObjectsByKey(
    endorsements.filter((endo) => endo.status !== EndoState.ENDO_COMPLETE),
    (endo) => endo.policyId,
  );
  for(let cdAccount of cdAccounts){
    if(cdAccount.policies.find((policyId) => policyGroupedEndo[policyId]?.length)){
      return cdAccount.id;
    }
  }
  return cdAccounts[0]?.id;
};
