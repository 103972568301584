import { IPolicyListItemType } from './../redux/slices/hrdRevampRedux/types';
import LoopEndpoints from './LoopEndpoints';
import { get, ISuccessResponse } from './providers';

const PolicyApi = {
  getPolicyDetail: (policyId: string) =>
    get(LoopEndpoints.fetchPolicyPlan(policyId)),

  getPolicies: (companyId: string) =>
    get<ISuccessResponse<IPolicyListItemType[]>>(LoopEndpoints.fetchPolicies(companyId)),

  getPolicyMembers: (policyId: string, companyId: string) =>
    get(LoopEndpoints.fetchCompanyEmployeesByPolicy(companyId, policyId)),

  downloadPolicyDocument: async (policyId: string, fileIdentifier: string) =>
    await get(LoopEndpoints.downloadPolicyDocument(policyId, fileIdentifier)),
};

export default PolicyApi;
