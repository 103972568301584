import { useEffect, useState } from 'react';
import {
  ICompanyListItem,
  ICompanyListResponseItem,
} from '../../../redux/slices/hrdRevampRedux/types';
import {
  capitalizeFirstLetterOfEachWord,
  parseResponse,
  showErrorToast,
} from '../../common/Utilities';
import GlobalSearchAPI from '../../../adaptars/globalSearchApi';
import { COMPANY_NICKNAMES } from '../../../components/containers/TopNavigation/constants';
import { isEndoNotificationEnabled, isEndoVisible } from '../../featureFlag';

export const useCompanyList = () => {
  const [loadingCompanyList, setLoadingCompanyList] = useState(true);
  const [companyList, setCompanyList] = useState<ICompanyListItem[]>([]);

  useEffect(() => {
    (async function () {
      setLoadingCompanyList(true);
      const [error, result] = await parseResponse(
        GlobalSearchAPI.getCompanyList(),
      );
      if (error) {
        showErrorToast('Unable to fetch company');
        setLoadingCompanyList(false);
        console.error(error);
        return;
      }

      const companies: ICompanyListItem[] = result?.data.companies
        .filter((company: ICompanyListResponseItem) => company.active)
        .map((company: ICompanyListResponseItem) => {
          const isEndoEnabled =
            isEndoVisible &&
            company.config?.isSelfServeEnabled &&
            company.isHRDRegistrationEnabledForUser;
          const isNotificationsEnabled =
            isEndoNotificationEnabled && company.isNotificationEnabledForUser;
          
          return {
            id: company.id,
            name: capitalizeFirstLetterOfEachWord(
              COMPANY_NICKNAMES[company.id]?.nickName ?? company.name,
            ),
            companyLogo: company.companyLogo,
            config: company.config,
            isEndoEnabled,
            isNotificationsEnabled,
          };
        });
      setCompanyList(companies);
      setLoadingCompanyList(false);
    })();
  }, []);

  return {
    companyList,
    loadingCompanyList,
  };
};
