import { useSelector } from 'react-redux';
import {
  selectCDAccountsList,
  selectClaimList,
  selectEndorsementList,
  selectPoliciesList,
} from '../../../../redux/slices/hrdRevampRedux';
import { useMemo } from 'react';
import { getRecommendedCDBalance } from '../../../pages/CDAccounts/utils';
import { IAlertData } from '../../ClaimAlertItem/types';
import { IClaimData } from '../../../pages/ClaimsList/types';
import { ICDAccount } from '../../../pages/CDAccounts/types';
import { IPolicyListItemType } from '../../../../redux/slices/hrdRevampRedux/types';
import { IEndorsements } from '../../../pages/Endorsements/ListingPage/types';

export default function useFetchAlerts(policyId?: string) {
  const policyList = useSelector(selectPoliciesList)?.data;
  const cdAccountList = useSelector(selectCDAccountsList)?.data;
  const claimList = useSelector(selectClaimList)?.data;
  const endorsementList = useSelector(selectEndorsementList)?.data;

  const { policyAlertMap, alerts, alertsCount } = useMemo(() => {
    const policyNo =
      policyId &&
      policyList.find((policy) => policy.id === policyId)?.policyNumber;
    const underQueryClaims = filterUnderQueryClaims(claimList, policyNo);
    const { policyAlertMap, alerts, alertsCount } = prepareAlertsData(
      policyList,
      cdAccountList,
      underQueryClaims,
      endorsementList,
      policyId,
    );
    return { policyAlertMap, alerts, alertsCount };
  }, [policyList, cdAccountList, claimList, policyId]);

  return {
    alerts,
    alertsCount,
    policyAlertMap,
  };
}

const filterUnderQueryClaims = (claimList: IClaimData[], policyNo?: string) => {
  return claimList?.filter(
    (claimData) =>
      claimData.status?.tag === 'Under Query' &&
      (!policyNo || claimData.policyNo === policyNo),
  );
};

const processLowCdAlerts = (
  policyData: IPolicyListItemType,
  account: ICDAccount | undefined,
  endorsements: IEndorsements[],
) => {
  if (!account) {
    return {
      isEndoBlocked: false,
      isLowCd: true,
    };
  }
  const endos = endorsements?.filter(
    (endo) =>
      ['DRAFT', 'IN_PROGRESS'].includes(endo.status) &&
      endo.policyId === policyData.id,
  );
  const endoCost = (endos && endos[0]?.endoCost) || 0;
  const recommendedCDBalance = getRecommendedCDBalance(account);
  const totalRecommendedCost = endoCost + recommendedCDBalance;
  const isLowCd = totalRecommendedCost > account.balance;
  const isEndoBlocked = endoCost > account.balance;
  return {
    isLowCd,
    isEndoBlocked,
  };
};

const prepareAlertsData = (
  policyList: IPolicyListItemType[],
  accounts: ICDAccount[],
  claimList: IClaimData[],
  endorsements: IEndorsements[],
  policyId?: string,
) => {
  const policyAlertMap = new Map<string, IAlertData>();
  const alerts: IAlertData[] = [];
  let alertsCount = 0;
  const filteredPolicies = policyId
    ? policyList.filter((policy) => policy.id === policyId)
    : policyList;
  filteredPolicies.forEach((policyData) => {
    const cdAccount = accounts.find((account) =>
      account.policies.includes(policyData.id),
    );
    const { isEndoBlocked, isLowCd } = processLowCdAlerts(
      policyData,
      cdAccount,
      endorsements,
    );
    if (!isLowCd) return;
    const underQueryClaims = claimList.filter(
      (claims) => claims.policyNo === policyData.policyNumber,
    ).length;

    const alertData = {
      id: policyData.id,
      insurerLogo: policyData.insurerLogo,
      nickName: policyData.nickName || '',
      policyType: policyData.policyType,
      policyId: policyData.id,
      cdAccountNumber: cdAccount?.accountNumber ?? '',
      claimAlerts: underQueryClaims,
      CDAccountAlerts: !!(cdAccount || isLowCd),
      isEndoBlocked: isEndoBlocked,
    };
    if (cdAccount || underQueryClaims || isLowCd) {
      policyAlertMap.set(policyData.id, alertData);
      alerts.push(alertData);
      alertsCount =
        alertsCount + underQueryClaims + (cdAccount || isLowCd ? 1 : 0);
    }
  });
  return { policyAlertMap, alerts, alertsCount };
};
