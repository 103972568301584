import { groupArrayOfObjectsByKey } from "../../../utils/common/Utilities";
import { ICDAccount } from "../../pages/CDAccounts/types";
import { IClaimData } from "../../pages/ClaimsList/types";

export const getClaimCountByStatusAndPolicyId = (claims: IClaimData[], policyNo: string) => {
    const policyClaims = claims.filter((item) => (item.policyNo === policyNo));
    return groupArrayOfObjectsByKey(
        policyClaims,
        (claim: IClaimData) => claim.status?.tag || '',
    );
}

export const getCdAccountFromList = (cdAccounts: ICDAccount[], policyId: string) => {
    return cdAccounts?.find((cdAccount) =>
        cdAccount.policies.includes(policyId)
    )
}