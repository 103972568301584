import { Typography } from '@loophealth/loop-ui-web-library';
import { formatCurrencyAmount } from '../../../utils/common/Utilities';
import { IEndorsements } from '../../pages/Endorsements/ListingPage/types';
import { StyledCDStatusSubText, StyledCDStatusTextWrapper } from './styles';
import { ICdBalanceStatus } from './types';
import { EndoState } from '../../pages/Employees/types';

export const getCDBalanceStatus = (
  balance: number,
  policies: string[],
  endorsements: IEndorsements[],
): ICdBalanceStatus => {
  let totalCost = 0,
    deductedAmount = 0;

  const endos = endorsements.filter(
    (endo) =>
      policies.includes(endo.policyId) &&
      endo.status !== EndoState.ENDO_COMPLETE,
  );

  endos.forEach((endo) => {
    const endoCost = endo.additionCost + endo.editsCost - endo.deletionCost;
    if (!['DRAFT', 'IN_PROGRESS'].includes(endo.status) && endoCost >= 0)
      deductedAmount += endoCost;
    totalCost += endoCost;
  });

  const unblockedCdBalance = balance + deductedAmount;

  if (!endos.length) {
    return {
      statusText: 'Current balance after last endorsements were completed.',
      title: 'Current CD Balance',
      subTitle:
        'Current CD balance shown is the final amount after your last endorsements were completed.',
      redirectType: 'Completed',
      unblockedCdBalance,
    };
  }
  if (totalCost >= 0) {
    return {
      title: 'Endorsement in Progress',
      redirectType: 'Ongoing',
      unblockedCdBalance,
      statusText: (
        <StyledCDStatusTextWrapper>
          <Typography variant="extraSmall" color="error">
            *
          </Typography>
          <Typography variant="extraSmall" color="primary" weight="semiBold">
            {formatCurrencyAmount(totalCost)} blocked
          </Typography>
          &nbsp;for processing ongoing endorsements.
        </StyledCDStatusTextWrapper>
      ),
      subTitle: (
        <StyledCDStatusSubText>
          <div>
            An amount of {formatCurrencyAmount(totalCost)} has been blocked for
            processing ongoing endorsements.
          </div>
          <div>
            Final CD balance will update once endorsement is processed by
            insurer.
          </div>
        </StyledCDStatusSubText>
      ),
    };
  }
  return {
    title: 'Endorsement in Progress',
    redirectType: 'Ongoing',
    unblockedCdBalance,
    statusText: (
      <StyledCDStatusTextWrapper>
        <Typography variant="extraSmall" color="error">
          *
        </Typography>
        Approx.&nbsp;
        <Typography variant="extraSmall" color="primary" weight="semiBold">
          refund of {formatCurrencyAmount(Math.abs(totalCost))}
        </Typography>
        &nbsp;for ongoing endorsements.
      </StyledCDStatusTextWrapper>
    ),
    subTitle: (
      <StyledCDStatusSubText>
        <div>
          An approximate amount of {formatCurrencyAmount(Math.abs(totalCost))}{' '}
          will be refunded after processing ongoing endorsements.
        </div>
        <div>
          Final CD balance will update once the endorsement is processed by
          insurer.
        </div>
      </StyledCDStatusSubText>
    ),
  };
};
