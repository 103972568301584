import { IBulkAddData } from '../types';
import { IBulkAddExcelData } from './types';
import { read, utils } from 'xlsx';
import { IRelationship } from '../../../../containers/Bulk/types';
import { DateFormatUtils } from '@loophealth/loop-ui-web-library';
import { ITrackTask } from '../../../../../utils/segment/hooks/useSegment';
import { trackTaskEvent } from '../../../../../utils/segment/utils';
import { formatGender, formatRelationship } from '../../../../../utils/common/mapping';

export const formatBackendDate = (inputDate: string) => {
  const dateFormatUtils = new DateFormatUtils();
  const isValid = dateFormatUtils.validateExcelDateFormat(inputDate);
  if (isValid) return dateFormatUtils.excelToBackendDateFormat(inputDate);
  return inputDate;
};

export const formatExcelDatesForBulkUpload = (
  excelMemberData: IBulkAddExcelData,
): IBulkAddData => {
  return {
    employee_id: excelMemberData['Employee ID'] ?? '',
    name: excelMemberData['Member Name'] ?? '',
    relationship_to_account_holders: formatRelationship(
      excelMemberData['Relationship'],
    ),
    gender: formatGender(excelMemberData['Gender (Male/Female)']),
    mobile: excelMemberData['Mobile'] || '',
    email_address: excelMemberData['Email'] || '',
    date_of_birth: formatBackendDate(
      excelMemberData['Date of Birth (DD/MMM/YYYY)'] || '',
    ),
    policy_start_date: formatBackendDate(
      excelMemberData['Date of Joining (DD/MMM/YYYY)'] || '',
    ),
    sumInsured: Number(excelMemberData['Sum Insured'] ?? ''),
    grade: excelMemberData['Designation or Grade'] || '',
    ctc: excelMemberData['CTC'] || '',
  };
};

export const processFileUpload = async (
  files: Record<string, unknown>[],
  setSheetData: React.Dispatch<
    React.SetStateAction<Record<string, IBulkAddData[]>>
  >,
  setSheetSelected: React.Dispatch<
    React.SetStateAction<Record<string, unknown>[]>
  >,
  toast: any,
  trackTask: ITrackTask,
  pathname: string,
  companyPolicyIds: string[],
): Promise<void> => {
  const sheetData: Record<string, IBulkAddData[]> = {};
  if (!files.length) {
    setSheetSelected([]);
    setSheetData({});
    return;
  }
  const file = files[0];
  const reader = new FileReader();

  reader.onload = (e) => {
    const policyIds: string[] = [];
    const data = e.target?.result;
    const workbook = read(data, { type: 'binary' });
    const sheetNames = workbook.SheetNames;
    const indexOfReadMe = sheetNames.indexOf('READ ME');
    if (indexOfReadMe > -1) {
      const jsonDataReadMe = utils.sheet_to_json(
        workbook.Sheets[sheetNames[indexOfReadMe]],
        {
          range: 1,
          raw: false,
        },
      );
      const getPolicyIds = extractUniquePolicyIds(jsonDataReadMe);
      policyIds.push(...getPolicyIds);
    }
    const sheetHasInvalidPolicyIds = policyIds.some(
      (policyId) => !companyPolicyIds.includes(policyId),
    );
    if (sheetHasInvalidPolicyIds) {
      setSheetSelected([]);
      toast.error('Invalid or tampered file uploaded');
      return;
    }
    if (policyIds.length) {
      sheetNames.forEach((sheetName: string, sheetIndex) => {
        const jsonData = utils.sheet_to_json(
          workbook.Sheets[sheetNames[sheetIndex]],
          {
            range: 1,
            raw: false,
          },
        );

        if (sheetName !== 'READ ME') {
          const filteredRecords = jsonData.map((data) =>
            formatExcelDatesForBulkUpload(data as IBulkAddExcelData),
          );
          if (filteredRecords.length)
            sheetData[policyIds[sheetIndex - 1]] = filteredRecords;
        }
      });
    }
    if (Object.keys(sheetData).length) {
      setSheetData(sheetData);
      trackTask(trackTaskEvent('Load_Upload_Proceed_Button_delete', pathname));
    } else {
      setSheetSelected([]);
      toast.error('Empty File');
    }
  };
  reader.readAsBinaryString(file as unknown as Blob);
  setSheetSelected(files);
};

export const extractPolicyIds = (dataArray: any) =>
  dataArray
    .filter(
      (item: any) => item['__EMPTY_4'] && item['__EMPTY_4'].startsWith('LPP-'),
    )
    .map((item: any) => item['__EMPTY_4']);

export const extractUniquePolicyIds = (dataArray: any[]): string[] => {
  const policyIdSet = new Set<string>();

  dataArray.forEach((item) => {
    for (const key in item) {
      if (
        item[key] &&
        typeof item[key] === 'string' &&
        item[key].startsWith('LPP-')
      ) {
        policyIdSet.add(item[key]);
      }
    }
  });

  return Array.from(policyIdSet);
};
