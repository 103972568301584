import { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { parseResponse } from '../../../../utils/common/Utilities';
import LoopApiService from '../../../../adaptars/LoopApiService';
import { processTimelineDataUI } from '../../../containers/ClaimDetails/utils';
import {
  defaultTimeline,
} from '../../../containers/ClaimsTimeline/constant';
import { ITimelineElement } from '../../../containers/ClaimsTimeline/types';

const useFetchClaimTimeline = (claimId: string) => {
  const [timelineLoading, toggleLoading] = useState<boolean>(false);
  const [claimTimelineData, setClaimTimelineData] = useState<
    ITimelineElement[] | null
  >(null);
  useEffect(() => {
    let isMounted = true;

    const fetchClaimTimelineDetails = async () => {
      if (claimId) {
        toggleLoading(true);

        let error = null;
        let result = null;

        [error, result] = await parseResponse(
          LoopApiService.fetchClaimTimelineAPI(claimId),
        );

        if (isMounted) {
          toggleLoading(false);
          if (error) {
            toast.error(error.message || 'Something went wrong!');
            console.error(error);
            return;
          }
          const claimTimelineData = result.data?.timeline;
          const updatedTimeline = processTimelineDataUI(
            defaultTimeline,
            claimTimelineData,
          );
          setClaimTimelineData(updatedTimeline);
        }
      }
    };

    fetchClaimTimelineDetails();

    return () => {
      isMounted = false;
    };
  }, [claimId]);

  const memoizedClaimTimelineData = useMemo(
    () => claimTimelineData,
    [claimTimelineData],
  );

  return { timelineLoading, claimTimelineData: memoizedClaimTimelineData };
};

export default useFetchClaimTimeline;
