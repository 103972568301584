import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCDAccountsList,
  selectSelectedCompany,
} from '../../../../redux/slices/hrdRevampRedux';
import { fetchCDAccounts } from '../../../../redux/slices/hrdRevampRedux/thunk';

const useFetchCDAccounts = () => {
  const dispatch = useDispatch();
  const selectedCompany = useSelector(selectSelectedCompany);
  const { data, error, status } = useSelector(selectCDAccountsList);
  const isLoading = status === 'loading';

  useEffect(() => {
    if (selectedCompany && !isLoading) {
      dispatch(fetchCDAccounts(selectedCompany.id));
    }
  }, [selectedCompany?.id, dispatch]);

  return { cdAccounts: data, isLoading, error };
};
export default useFetchCDAccounts;
