import {
  Alerts,
  CardIcon,
  DateFormatUtils,
  LoopButton,
  Shadow,
  Typography,
} from '@loophealth/loop-ui-web-library';
import { FC, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import {
  CurrencyRedIcon,
  CurrencyTurqIcon,
  DownloadDisabledIcon,
  DownloadIcon,
} from '../../../assets/images';
import theme from '../../../theme';
import WithThemeProvider from '../../../theme/WithThemeProvider';
import { formatCurrencyAmount } from '../../../utils/common/Utilities';
import useSegment from '../../../utils/segment/hooks/useSegment';
import { extractPathName, trackClickEvent } from '../../../utils/segment/utils';
import RecommendedCdBreakdownModal from '../RecommendedCdBreakdownModal';
import {
  ShadowWrapper,
  StyledBalanceDetail,
  StyledBalanceWrapper,
  StyledCdBalance,
  StyledCdBalanceBox,
  StyledCdBalanceContainer,
  StyledColumn,
  StyledContainer,
  StyledCostBalanceContainer,
  StyledEclipse,
  StyledIconContent,
  StyledMainContainer,
} from './styles';
import { downloadCDStatementFile } from './utils';
import { transformCdEndoAlerts } from '../RecommendedCdBreakdownModal/utils';
import { useSelector } from 'react-redux';
import {
  selectEndorsementList,
  selectSelectedCompany,
} from '../../../redux/slices/hrdRevampRedux';
import CDBalanceStatus from '../CDBalanceStatus';
import { getCDBalanceStatus } from '../CDBalanceStatus/utils';
import { ICDAccountInfo } from '../CDBalanceStatus/types';
import { isNewCDBalanceDetailsEnabled } from '../../../utils/featureFlag';

const CDAccountInfo: FC<ICDAccountInfo> = ({ data, isFirstOngoing }) => {
  const trackClick = useSegment('click');
  const { formatDate } = new DateFormatUtils();
  const endorsements = useSelector(selectEndorsementList).data;
  const { isSufficient, isCdLow, totalRecommendedCost } = transformCdEndoAlerts(
    data,
    endorsements,
  );
  const insurerLogo = data.policyData[0]?.insurerLogo;
  const isDownloadEnabled = data.cdDocument !== '';
  const [downloadingCDStatement, setDownloadingCDStatement] =
    useState<boolean>(false);

  const selectedCompany = useSelector(selectSelectedCompany);

  const cdBalanceDetails = useMemo(
    () => getCDBalanceStatus(data.balance, data.policies, endorsements),
    [data, endorsements],
  );

  const onDownloadCdClick = async () => {
    if (!data.cdDocument) return;
    setDownloadingCDStatement(true);

    try {
      await downloadCDStatementFile(data.id, data.cdDocument);
    } catch (error) {
      toast.error((error as Error)?.message);
    }

    trackClick(
      trackClickEvent(
        `Download_Statement_CTA_${data.insurer}`,
        window.location?.pathname,
        extractPathName(window.location?.pathname),
        {
          clickProperties: {},
          ...{
            documentDownloaded: data.cdDocument,
          },
        },
      ),
    );
    setDownloadingCDStatement(false);
  };

  const [isLowCDModalVisible, setIsLowCdModalVisible] = useState(false);

  const description = (
    <Typography variant="small" weight="medium" color="error">
      CD Balance Low. Please add funds to process your future endorsements
      smoothly!
    </Typography>
  );

  return (
    <ShadowWrapper>
      <Shadow variant="nav">
        <WithThemeProvider>
          <StyledContainer>
            <StyledMainContainer>
              <StyledIconContent>
                <CardIcon imageUrl={insurerLogo} />
                <StyledColumn>
                  <Typography
                    variant="small"
                    weight="regular"
                    color="secondary"
                  >
                    Account no:&nbsp;
                    <Typography
                      variant="medium"
                      weight="medium"
                      color="primary"
                    >
                      {data.accountNumber}
                    </Typography>
                  </Typography>
                  <Typography variant="small" color="secondary">
                    {data.insurer}
                  </Typography>
                </StyledColumn>
              </StyledIconContent>

              <StyledCostBalanceContainer>
                <LoopButton
                  variant={isDownloadEnabled ? 'secondary' : 'disabled'}
                  onClick={onDownloadCdClick}
                  isLoading={downloadingCDStatement}
                  size="medium"
                  iconSrc={
                    isDownloadEnabled ? DownloadIcon : DownloadDisabledIcon
                  }
                  iconOrder="left"
                  borderColor={theme.colors.loopEmerald}
                >
                  Download Insurer CD Statement
                </LoopButton>
                {isDownloadEnabled && (
                  <Typography variant="extraSmall" color="secondary">
                    Last Updated:{' '}
                    <Typography variant="extraSmall" weight="medium">
                      {formatDate(new Date(data.cdDocumentUpdatedAt))}
                    </Typography>
                  </Typography>
                )}
              </StyledCostBalanceContainer>
            </StyledMainContainer>

            <StyledCdBalanceContainer>
              <StyledCdBalanceBox>
                <StyledCdBalance>
                  <img src={isCdLow ? CurrencyRedIcon : CurrencyTurqIcon} />
                  <StyledBalanceWrapper>
                    <Typography
                      variant="small"
                      weight="regular"
                      color="primary"
                    >
                      Available CD balance
                    </Typography>

                    <StyledBalanceDetail>
                      <Typography
                        variant="title3"
                        weight="medium"
                        color="primary"
                      >
                        {formatCurrencyAmount(
                          isNewCDBalanceDetailsEnabled
                            ? cdBalanceDetails.unblockedCdBalance
                            : data.balance,
                        )}
                      </Typography>
                      <StyledEclipse />
                      <Typography
                        variant="extraSmall"
                        weight="regular"
                        color="secondary"
                      >
                        Last Updated: {formatDate(data.updatedAt)}
                      </Typography>
                    </StyledBalanceDetail>

                    {isNewCDBalanceDetailsEnabled && (
                      <CDBalanceStatus
                        cdBalanceDetails={cdBalanceDetails}
                        isFirstOngoing={isFirstOngoing}
                      />
                    )}
                  </StyledBalanceWrapper>
                </StyledCdBalance>
              </StyledCdBalanceBox>
              {isCdLow && (
                <StyledCdBalanceBox>
                  <StyledCdBalance>
                    <img src={CurrencyTurqIcon} />
                    <StyledBalanceWrapper>
                      <Typography
                        variant="small"
                        weight="regular"
                        color="primary"
                      >
                        Recommended CD balance
                      </Typography>

                      <StyledBalanceDetail>
                        <Typography
                          variant="title3"
                          weight="medium"
                          color="emerald"
                        >
                          {formatCurrencyAmount(totalRecommendedCost)}
                        </Typography>
                        {isSufficient && (
                          <>
                            <StyledEclipse />
                            <Typography
                              variant="extraSmall"
                              weight="regular"
                              color="secondary"
                            >
                              Based on 10% of your inception premium
                            </Typography>
                          </>
                        )}
                      </StyledBalanceDetail>
                    </StyledBalanceWrapper>
                  </StyledCdBalance>
                </StyledCdBalanceBox>
              )}
            </StyledCdBalanceContainer>
            {isCdLow &&
              (!selectedCompany?.isEndoEnabled || isSufficient ? (
                <Alerts.StatusAlert text={description} variant="over" />
              ) : (
                <Alerts.StatusAlert
                  text={description}
                  variant="over"
                  onButtonClick={() => setIsLowCdModalVisible(true)}
                  buttonText="View balance breakdown"
                />
              ))}
          </StyledContainer>
        </WithThemeProvider>
        <RecommendedCdBreakdownModal
          data={data}
          isVisible={isLowCDModalVisible}
          setIsVisible={setIsLowCdModalVisible}
        />
      </Shadow>
    </ShadowWrapper>
  );
};

export default CDAccountInfo;
