import { IAlertData } from '../ClaimAlertItem/types';

// need to update once api is integrated
export const getAlertText = (alertData: IAlertData) => {
  const isCdBalanceLow = alertData.CDAccountAlerts;
  const claimsUnderQuery = alertData.claimAlerts;
  const cdBalanceAlertText = claimsUnderQuery
    ? 'CD Balance and '
    : 'CD Balance is low!';
  let text = isCdBalanceLow ? cdBalanceAlertText : '';

  if (claimsUnderQuery) {
    text +=
      claimsUnderQuery === 1
        ? `${claimsUnderQuery} Claim need your attention!`
        : `${claimsUnderQuery} Claims need your attention!`;
  }
  return text;
};
