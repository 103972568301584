import {
  TickCircleDarkIcon,
  YellowSearchIcon,
  WarningRoundedIcon,
  DataSubmissionInactive,
  SearchInactive,
  DataSentInActive,
  UhidGeneratedInactive,
  EcardInactive,
  DataSentActive,
  UhidGeneratedActive,
  EcardActive,
} from '../../../assets/images';
import { IEndoTimelineElement } from './types';

export const defaultEndoTimeline: IEndoTimelineElement[] = [
  {
    id: 'data_submission',
    status: 'data_submission',
    title: 'Data Submission',
    active: false,
    displayActive: false,
    displayDefault: true,
    description: 'Data submitted',
    date: '',
    successIcons: {
      active: DataSentActive,
      success: TickCircleDarkIcon,
      disabled: DataSubmissionInactive,
    },
  },
  {
    id: 'loop_review',
    title: 'Review by Loop',
    status: 'loop_review',
    description: 'Review completed',
    active: false,
    displayActive: false,
    displayDefault: true,
    date: '',
    successIcons: {
      active: YellowSearchIcon,
      disabled: SearchInactive,
      success: TickCircleDarkIcon,
    },
  },
  {
    id: 'data_sent',
    title: 'Data sent to Insurer',
    status: 'data_sent',
    active: false,
    displayActive: false,
    displayDefault: true,
    description: 'Insurer will review the data to process the endorsement',
    successIcons: {
      active: DataSentActive,
      disabled: DataSentInActive,
      success: TickCircleDarkIcon,
    },
  },
  {
    id: 'endo_rejected',
    title: 'Review by Insurer',
    status: 'endo_rejected',
    description: 'Please contact your CSM for details',
    active: false,
    displayActive: false,
    displayDefault: false,
    successIcons: {
      active: WarningRoundedIcon,
      disabled: EcardInactive,
      success: TickCircleDarkIcon,
    },
  },
  {
    id: 'uhid_generated',
    title: 'UHID Generated',
    status: 'uhid_generated',
    active: false,
    displayActive: false,
    displayDefault: true,
    description: 'TPA has generated UHIDs for all approved lives',
    successIcons: {
      active: UhidGeneratedActive,
      disabled: UhidGeneratedInactive,
      success: TickCircleDarkIcon,
    },
  },
  {
    id: 'ecard_received',
    title: 'E-Cards Received',
    status: 'ecard_received',
    description: 'Received E-Cards for all lives approved lives',
    active: false,
    displayActive: false,
    displayDefault: true,
    successIcons: {
      active: EcardActive,
      disabled: EcardInactive,
      success: TickCircleDarkIcon,
    },
  },
];
