import React, { FC } from 'react';
import {
  PieChartContainer,
  StyledEmployeeStatistics,
  StyledPieChartContainer,
} from './styles';
import Card from '../../../atoms/Card';
import { PieChart, Typography } from '@loophealth/loop-ui-web-library';
import CustomLegend from '../../../atoms/PieChart/CustomLegend';
import CenteredText from '../../../atoms/PieChart/CenterText';
import { IEmployeeStatisticsProps } from './types';
import { getECardStatistics, getTotalInsuredLivesStatistics } from './utils';
import { ICenteredTextType } from '../../../atoms/PieChart/CenterText/types';

const EmployeeStatistics: FC<IEmployeeStatisticsProps> = ({
  insuredCount,
  eCardsAvailableCount,
  eCardsInProgressCount,
  endorsementInProgressCount,

  insuredChildrenCount,
  insuredEmployeesCount,
  insuredParentsCount,
  insuredParentsInLawCount,
  insuredSpousesCount,
  insuredEmp,
  inProgressEmp,
  isGPAorGTL,
}) => {
  const eCards = getECardStatistics(
    insuredCount,
    eCardsAvailableCount,
    eCardsInProgressCount,
    endorsementInProgressCount,
    isGPAorGTL,
    insuredEmp,
    inProgressEmp,
  );

  const totalInsuredLives = getTotalInsuredLivesStatistics(
    insuredChildrenCount,
    insuredEmployeesCount,
    insuredParentsCount,
    insuredParentsInLawCount,
    insuredSpousesCount,
    isGPAorGTL,
  );

  return (
    <StyledEmployeeStatistics>
      <Card joined={!!totalInsuredLives} rounded={false}>
        <PieChartContainer>
          <Typography variant="small" weight="medium" color="secondary">
            {isGPAorGTL
              ? 'Insurance Status for Total Employees'
              : 'E-Card Status for Total Lives'}
          </Typography>
          <StyledPieChartContainer>
            <PieChart
              data={eCards.data}
              centerText={
                <CenteredText
                  value={eCards.percentage}
                  label={isGPAorGTL ? 'Employees' : 'Lives'}
                  type={ICenteredTextType.semiDoughnut}
                />
              }
              width={180}
              legends="custom"
              type="semi-doughnut"
              renderLegend={(item) => (
                <CustomLegend
                  color={item.color}
                  count={item.value}
                  percentage={item.percentage}
                  label={item.label}
                />
              )}
              tooltipText="E-cards"
            />
          </StyledPieChartContainer>
        </PieChartContainer>
      </Card>

      {totalInsuredLives && (
        <Card joined={!!totalInsuredLives} rounded={false}>
          <PieChartContainer>
            <Typography variant="small" weight="medium" color="secondary">
              Relationship breakdown for Total Lives
            </Typography>
            <StyledPieChartContainer>
              <PieChart
                data={totalInsuredLives.data}
                centerText={
                  <CenteredText
                    value={totalInsuredLives.count}
                    label="Total Lives"
                  />
                }
                width={180}
                legends="custom"
                renderLegend={(item) => (
                  <CustomLegend
                    color={item.color}
                    count={item.value}
                    percentage={item.percentage}
                    label={item.label}
                  />
                )}
                tooltipText="Lives"
              />
            </StyledPieChartContainer>
          </PieChartContainer>
        </Card>
      )}
    </StyledEmployeeStatistics>
  );
};

export default EmployeeStatistics;
