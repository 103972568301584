import styled from "styled-components";

export const StyledZeroSearchResult = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
`;

export const StyledZeroSearchResultTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
`;

export const StyledZeroSearchText = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    width: 100%;
`;