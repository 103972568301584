import { DateFormatUtils } from '@loophealth/loop-ui-web-library';
import {
  convertToDate,
  deleteUserFileFromBucket,
  parseResponse,
  uploadUserFileToBucket,
} from '../../../../utils/common/Utilities';
import { IButtonState } from '../../../containers/Bulk/types';
import { IBulkEditData, IBulkEditEntity, IBulkEditSubmitRequest, IEditableFields } from './types';
import LoopApiService from '../../../../adaptars/LoopApiService';

export const getButtonStates = (
  currentStep: number,
  selectedUsers: unknown[],
  isLoading: boolean,
): IButtonState => {
  const currentLength = selectedUsers.length;
  switch (currentStep) {
    case 1:
      return {
        isNextEnabled: !!currentLength,
        isBackEnabled: false,
        buttonText: `View Cost of Editing ${currentLength} Members`,
        backButtonText: '',
      };
    case 2:
      return {
        isNextEnabled: !isLoading,
        isBackEnabled: true,
        buttonText: `Submit ${currentLength} Members`,
        backButtonText: 'Review Again',
      };
    default:
      return {
        isNextEnabled: false,
        isBackEnabled: false,
        buttonText: '',
        backButtonText: '',
      };
  }
};

export const transformEditEntityIntoData = (
  edit: IBulkEditEntity,
): IBulkEditData => {
  const { selfName, firstName, lastName, edits, policies, ...primaryDetails } =
    edit;
  Object.keys(edits).forEach((field) => {
    const fieldEdit = edits[field as IEditableFields];
    primaryDetails[fieldEdit?.field!] = fieldEdit?.after!;
  });
  return primaryDetails;
};

const removeDuplicateFiles = (files: File[]) => {
  const uniqueFiles = files.reduce((prevFiles, file) => {
    const hashString = `${file.name}_${file.size}_${file.type}_${file.lastModified}`;
    prevFiles[hashString] = file;
    return prevFiles;
  }, {} as Record<string, File>);
  return Object.values(uniqueFiles);
};

export const submitBulkEditEndorsement = async (
  editedUsers: IBulkEditEntity[],
): Promise<void> => {
  const uploadedDocs: string[] = [];
  const formatDate = new DateFormatUtils().excelToBackendDateFormat;
  try {
    const bulkEditRequest = await Promise.all(
      editedUsers.map(async (editedUser) => {
        const documents: string[] = [];
        const finalEdit = transformEditEntityIntoData(editedUser);
        const uniqueDocuments = removeDuplicateFiles(
          Object.values(editedUser.edits)
            .map((edit) => edit.documents)
            .flat(),
        );

        await Promise.all(
          uniqueDocuments.map(async (doc) => {
            const filePath = await uploadUserFileToBucket(
              finalEdit.employeeId,
              finalEdit.name,
              doc,
            );
            uploadedDocs.push(filePath);
            documents.push(filePath);
          }),
        );

        return {
          companyId: editedUser.companyId,
          policies: editedUser.policies.map((policy) => ({
            id: policy.id,
            slab: policy.slabId,
            policyStartDate: convertToDate(policy.policyStartDate),
            enrolmentDueDate: convertToDate(policy.enrolmentDueDate),
          })),
          member: {
            userId: finalEdit.userId,
            employee_id: finalEdit.employeeId,
            date_of_birth: formatDate(finalEdit.dob),
            email_address: finalEdit.email,
            mobile: finalEdit.mobile,
            gender: finalEdit.gender,
            name: finalEdit.name,
            relationship_to_account_holders: finalEdit.relationship,
            ctc: finalEdit.ctc,
            kycDocumentURLs: documents,
          },
        } as IBulkEditSubmitRequest;
      }),
    );

    const [error, response] = await parseResponse(
      LoopApiService.submitBulkEditRequest(bulkEditRequest),
    );
    if (error || !response) {
      throw error;
    }
  } catch (e) {
    setTimeout(() => {
      uploadedDocs.forEach((doc) =>
        deleteUserFileFromBucket(doc).catch((e) => {}),
      );
    }, 2000);
    throw e;
  }
};
